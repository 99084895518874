/* mulish-200 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/mulish-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/mulish-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/mulish-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-700 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/mulish-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/assets/icons/material-regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/assets/icons/material-regular.woff2") format("woff2"),
    url("/assets/icons/material-regular.woff") format("woff"),
    url("/assets/icons/material-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/assets/icons/material-outline.eot"); /* For IE6-8 */
  src: local("Material Icons Outlined"),
    url("/assets/icons/material-outline.woff2") format("woff2"),
    url("/assets/icons/material-outline.woff") format("woff"),
    url("/assets/icons/material-outline.otf") format("opentype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
  text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
  -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
  font-feature-settings: "liga"; /* Support for IE. */
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
}