@use 'sass:map';

$cs-blue: rgba(15, 76, 129, 1);
$cs-blue-transparent: rgba(15, 76, 129, .08);
$cs-yellow: #F3AD24;
$cs-citrus: #F7c331;


/* For use in src/lib/core/theming/_palette.scss */
// Created with: http://mcg.mbitson.com/#!?careerscope=%230f4c81&themename=mcgtheme 

$md-careerscope: (50 : #e2eaf0,
  100 : #b7c9d9,
  200 : #87a6c0,
  300 : #5782a7,
  400 : #336794,
  500 : #0f4c81,
  600 : #0d4579,
  700 : #0b3c6e,
  800 : #083364,
  900 : #042451,
  A100 : #84adff,
  A200 : #518cff,
  A400 : #1e6aff,
  A700 : #0459ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  ));

  $md-cs-red:  (
    50 : #f4e5e5,
    100 : #e4bebe,
    200 : #d39393,
    300 : #c16767,
    400 : #b34747,
    500 : #a62626,
    600 : #9e2222,
    700 : #951c1c,
    800 : #8b1717,
    900 : #7b0d0d,
    A100 : #ffadad,
    A200 : #ff7a7a,
    A400 : #ff4747,
    A700 : #ff2d2d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
  );

$cs-warn: map.get($md-cs-red, 500);