// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@use '@angular/material' as mat;
@use './styles/mixin' as *;
@use './styles/variables' as *;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $CareerScope-primary: mat.define-palette(mat.$indigo-palette, 800);
$CareerScope-primary: mat.m2-define-palette($md-careerscope, 500, 800, 300);
$CareerScope-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$CareerScope-warn: mat.m2-define-palette($md-cs-red);

$CareerScope-typography: mat.m2-define-typography-config($font-family: 'Mulish, sans-serif',
    $button: mat.m2-define-typography-level(1rem, 1.5rem, 400, $font-family: 'Mulish', $letter-spacing: 0),
    $body-1: mat.m2-define-typography-level(1rem, 1.5rem, 400, $font-family: 'Mulish', $letter-spacing: 0),
    $body-2: mat.m2-define-typography-level(1rem, 1.5rem, 400, $font-family: 'Mulish', $letter-spacing: 0),
    $headline-5: mat.m2-define-typography-level(1.5rem, 2.25rem, 700, $font-family: 'Mulish'),
    $headline-6: mat.m2-define-typography-level(1.35rem, 2rem, 400, $font-family: 'Mulish'), );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$CareerScope-theme: mat.m2-define-light-theme((color: (primary: $CareerScope-primary,
        accent: $CareerScope-accent,
        warn: $CareerScope-warn,
      ),
      typography: $CareerScope-typography ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-typographies($CareerScope-typography);
@include mat.all-component-themes($CareerScope-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($CareerScope-theme);

// $custom-typography: mat.define-legacy-typography-config($font-family: 'Mulish, sans-serif',
//   $body-1: mat.define-typography-level(1rem, 1.8rem, 400),
//   $title: mat.define-typography-level(1.35rem, 2rem, 400),
//   $headline: mat.define-typography-level(1.5rem, 2.25rem, 700));

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
// @include mat.legacy-core();

/* You can add global styles to this file, and also import other style files */

* {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

html,
body {
  overflow: auto;
  height: 100%;
  width: 100%;
  --mdc-linear-progress-active-indicator-height: 1rem;
  --mdc-linear-progress-track-height: 1rem;
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 1);
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 1);
}

body {
  margin: 0;
  padding: 0;
}


img {
  object-fit: contain;
  width: 100%;
}



h2 {
  color: $cs-blue;
}


.container {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

// portrait small tablet
// landscape medium handset
@media (min-width: 600px) {
  * {
    font-size: 14px;
  }
}

// portrait large tablet
// landscape large handset
@media (min-width: 840px) {
  * {
    font-size: 14px;
  }
}

@media (min-width: 1024px) and (min-height: 769px) {
  * {
    font-size: 15px;
  }
}


@media (min-width: 1440px) and (min-height: 769px) {
  * {
    font-size: 16px;
  }

}

@media (min-width: 1920px) and (min-height: 1081px) {
  * {
    font-size: 18px;
  }
}


@media (min-width: 840px) {
  .container {
    max-width: 816px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 976px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}


@media (min-width: 1920px) {
  .container {
    max-width: 1400px;
  }
}

.mat-toolbar {
  --mat-toolbar-container-background-color: #{$cs-blue} !important;
  --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87) !important;
}


// Add Bootstrap table styling

table {
  border-collapse: collapse;
}

.table {
  // width: auto;
  margin-bottom: 1rem;
}

.table td,
.table th {
  padding: .75rem;
  //vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, .075);
}

.table th {
  text-align: left;
}

.table thead th[scope=col] {
  border-top: 0px;
  vertical-align: top;
}

.table th[scope=rowgroup] {
  font-weight: initial;
  vertical-align: middle;
}

.table th[scope=row] {
  font-weight: initial;
  vertical-align: middle;
}

.table tbody+tbody {
  border-top: 1px solid #dee2e6;
}


.table-sm th,
.table-sm td {
  padding: 0.3rem;
  font-size: .9rem;
}

.mat-mdc-card {
  border-radius: 16px !important;
}


mat-progress-bar {
  height: 0.5rem;
}


mat-progress-bar .mdc-linear-progress__buffer-bar {
  background: #E4E8EB !important;
}

mat-progress-bar .mdc-linear-progress__bar-inner {
  height: 0.5rem;
  border-top-width: 0.5rem;
  border-color: $cs-yellow;
  background-color: $cs-yellow;
}


// Ensure that the non labeled radio button is aligned center
.naked-radio {

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label-content {
    padding: 0 !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-outline {
  background-color: white !important;
}

.occupation-detail-modal {

  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 0;
    border-radius: 16px;

    @media (min-width: 599.99px) and (orientation: portrait),
    (min-width: 959.99px) and (orientation: landscape) {
      max-width: 50%;
      margin: auto;
    }
  }
}

.row-multi-buttons {
  margin-top: 2rem;
}

.primary-color {
  color: $cs-blue;
}

.warn-color {
  color: $cs-warn;
}